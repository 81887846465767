import styled from 'styled-components';

export const ReportParametersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;
  
  h1{
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%; 
  }
`