import React, {useCallback, useEffect, useState} from 'react';

import {observer} from 'mobx-react-lite';
import {Router} from "./appRouting/RoutesGeneral";

import {useAuthStore} from '../../../store/hooks';
import {GlobalLoader} from './globalLoader/GlobalLoader';
import {GlobalStyle} from "./globalStyle/GlobalStyle";
import {Main} from "./main/Main";
import {CheckUser} from "./checkUser/CheckUser";
import {RoleCategory} from "../../../constants/projectUsers";
import {createTheme, ThemeProvider} from '@mui/material';
import {useAuthApi} from "../../../apiHooks";

export const AppLayer = observer(() => {
  const {isUserLoad, roleCategory} = useAuthStore();
  const [refreshed, setRefreshed] = useState(false);

  const {serverLogoutUser} = useAuthApi();

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 900,
        lg: 1200,
        xl: 1489,
      },
    }
  })
  const serverLogoutUserHandler = useCallback(() => {
    void serverLogoutUser();
  }, [serverLogoutUser]);

  useEffect(() => {
    const handleWindowClose = () => {
      if (!refreshed) {
        serverLogoutUserHandler();
      }
    };

    const handleRefresh = () => {
      setRefreshed(true);
    };

    window.addEventListener('beforeunload', handleWindowClose);
    window.addEventListener('keydown', handleRefresh);

    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      window.removeEventListener('keydown', handleRefresh);
    };
  }, [refreshed, serverLogoutUserHandler]);


  return (
    <ThemeProvider theme={theme}>
      <Main background={roleCategory === RoleCategory.USER ? 'rgb(249, 252, 255)' : 'white'}>
        <GlobalStyle/>
        <CheckUser/>
        {isUserLoad ?
          <Router/> :
          <GlobalLoader/>}
        <GlobalLoader/>
      </Main>
    </ThemeProvider>
  );
});


