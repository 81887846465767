import styled from 'styled-components';
import theme from '../../../../theme/theme';

export const MultipleSelectStyled = styled.div<{ maxWidth?: string }>`
  height: 36px;
  max-width: ${props => props.maxWidth ? props.maxWidth : '244px'};
  width: 100%;
  margin-bottom: 16px;

  & select {
    margin-top: 4px;
    width: 244px;
    padding: 4px 8px 4px 16px;
  }

  & h5 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  & .MuiInputBase-input {
    padding: 0 !important
  }

  & .MuiList-root .MuiList-padding {
    border-bottom: 1px solid ${theme.colors.gray5};
  }

`;

export const StyledOptions = styled.div`
  padding: 10px 0;
  border-top: 1px solid ${theme.colors.gray5};
  border-bottom: 1px solid ${theme.colors.gray5};
`;