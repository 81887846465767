import { Modal } from '../../../../../../shared/modal/Modal';

import React, { FC } from 'react';
import {
  ConfirmationTextModal
} from '../../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled';
import { ContentModal } from '../../../../../../shared/modal/ContentModal';
import { Button } from '../../../../../../shared/button/Button';
import { useGenerateReportModal } from './useGenerateReportModal.vm';
import { Select } from '../../../../../../shared/form/select/Select';
import { ReportParametersContainer } from './GenerateReportModal.styled';
import { MultipleSelect } from '../../../../../../shared/form/multipleSelect/MultipleSelect';

interface IProps {
  closeModal: () => void;
}

export const GenerateReportModal: FC<IProps> = ({ closeModal }) => {
  const {
    yearAnnualReport,
    yearMonthlyReport,
    monthMonthlyReport,
    setYearAnnualReport,
    setYearMonthlyReport,
    setMonthMonthlyReport,
    yearOptions,
    monthsOptions,
    generateAnnualReport,
    generateMonthlyReport,
    areasOptions,
    handleChangeAreaYearly,
    areaAnnualReport,
    areaMonthlyReport,
    handleChangeAreaMonthly
  } = useGenerateReportModal();

  return (<Modal clickHandler={() => closeModal()}>
    <ContentModal
      text={<ConfirmationTextModal>
        <ReportParametersContainer>
          <h1>Zestawienie roczne:</h1>
          <Select width={'332px'} isDefaultValue={false} selectValue={yearAnnualReport} elementName={'Rok'}
                  setValue={setYearAnnualReport} optionsArray={yearOptions} />
          <MultipleSelect key={'area'} selectedValues={areaAnnualReport}
                          elementName={'areaYear'}
                          maxWidth={'332px'}
                          options={areasOptions ?? []}
                          handleChange={handleChangeAreaYearly} />
          <Button text={'Zapisz (plik XLS)'} size={'small'} backgroundColor={'black'}
                  color={'white'} clickHandler={generateAnnualReport}
                  width={'190px'}></Button>
        </ReportParametersContainer>
        <ReportParametersContainer>
          <h1>Zestawienie miesięczne:</h1>
          <Select width={'332px'} isDefaultValue={false} selectValue={yearMonthlyReport} elementName={'Rok'}
                  setValue={setYearMonthlyReport} optionsArray={yearOptions} />
          <Select width={'332px'} isDefaultValue={false} selectValue={monthMonthlyReport} elementName={'Miesiąc'}
                  setValue={setMonthMonthlyReport} optionsArray={monthsOptions} />
          {/*<Select width={'332px'} isDefaultValue={true} defaultValue={'Wybierz'} selectValue={areaMonthlyReport}*/}
          {/*        elementName={'Obszar terapeutyczny'}*/}
          {/*        setValue={setAreaMonthlyReport} optionsArray={areasOptions} />*/}

          <MultipleSelect key={'area'} selectedValues={areaMonthlyReport}
                          elementName={'areaMonthly'}
                          maxWidth={'332px'}
                          options={areasOptions ?? []}
                          handleChange={handleChangeAreaMonthly} />
          <Button text={'Zapisz (plik PDF)'} size={'small'} backgroundColor={'black'}
                  color={'white'} clickHandler={generateMonthlyReport}
                  width={'190px'}></Button>
        </ReportParametersContainer>
      </ConfirmationTextModal>} />
  </Modal>);
};