import {createBrowserRouter, RouterProvider} from "react-router-dom";

import {adminLinks} from "./links/adminLinks";
import {useAuthStore} from "../../../../store/hooks";
import {RoleCategory} from "../../../../constants/projectUsers";
import {observer} from "mobx-react-lite";
import {generalLinks} from "./links/generalLinks";
import {GlobalLoader} from "../globalLoader/GlobalLoader";
import React from "react";
import {userLinks} from "./links/userLinks";

export const Router = observer(() => {
  const authStore = useAuthStore();

  switch (authStore.roleCategory) {
    case RoleCategory.ADMIN:
      return <RouterProvider router={createBrowserRouter(adminLinks)} fallbackElement={<GlobalLoader/>}/>;
    case RoleCategory.USER:
      return <RouterProvider router={createBrowserRouter(userLinks)} fallbackElement={<GlobalLoader/>}/>;
    default:
      return <RouterProvider router={createBrowserRouter(generalLinks)} fallbackElement={<GlobalLoader/>}/>;
  }
});
